<template>
<div class="display-block">
    <v-card>
        <v-card-text>
            <h3>Product Bundle Details :</h3>
            <row-item label="Title  " :value="productBundle.title"></row-item>
            <row-item label="Description : " :value="productBundle.desc"></row-item>
            <row-item label="Price : " :value="productBundle.price.toString()"></row-item>
            <row-item label="Type : " :value="productBundle.type=='SIMPLE'?'Simple':'Bundle'"></row-item>
            <row-item label="Buy Type : " :value="productBundle.bType=='FIXED'?'Fixed':'Customizable'"></row-item>
            <row-item label="Products : "></row-item>
            <v-layout v-if="productBundle.swapOptions?.length>0" wrap>
                <v-flex class="mx-2" sm4 xs12 v-for="product in (productBundle.bType=='FIXED'?productBundle.swapOptions:[productBundle.swapOptions[0]])" :key="product._id">
                    <v-card class="my-2">
                        <product-card class="mx-4" :product="product"></product-card>
                    </v-card>
                </v-flex>
            </v-layout>
            <row-item v-if="productBundle.swapOptions.length > 1 && productBundle.bType=='CUSTOMIZABLE'" label="swap Products : "></row-item>
            <v-layout v-if="productBundle.swapOptions.length > 1 && productBundle.bType=='CUSTOMIZABLE'" wrap>
                <v-flex class="mx-2" sm4 xs12 v-for="product in productBundle.swapOptions.slice(1)" :key="product._id">
                    <v-card class="my-2">
                        <product-card class="mx-4" :product="product"></product-card>
                    </v-card>
                </v-flex>
            </v-layout>
            <v-layout wrap>
                <v-flex xs12 sm4 md3 class="pa-1" v-for="image in productBundle.images" :key="image.filename" width="200">
                    <video-card :src="image.path" v-if="image.path&&image.mimetype.startsWith('video/')"></video-card>
                    <image-card :src="image.path" v-else-if="image.path&&image.mimetype.startsWith('image/')"></image-card>
                </v-flex>
            </v-layout>
        </v-card-text>
        <v-flex text-right>
            <v-btn @click="$router.go(-1)" class="ma-2" :style="theme">Back</v-btn>
        </v-flex>
    </v-card>
</div>
</template>

    
<script>
import RowItem from '@/components/RowItem'
import appConstants from '@/utils/appConstants'
import ProductCard from '@/components/ProductResponsiveCard'
export default {
    components: {
        RowItem,
        ProductCard
    },
    data() {
        return {
            id: 0,
            productBundle: {
                title:'',
                code:'',
                type:'',
                bType:'',
                price:0,
                swapOptions:[]
            },
            items: [],
        }
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            this.id = this.$route.params.id
            let product=await this.getItem(appConstants.PRODUCTS_API + "/" + this.id)
            if(product.swapOptions.length>0)
                product.swapOptions[0].swapOptions=[]
            this.productBundle= product
            this.productBundle?.swapOptions.forEach(rec=>{
                rec.price=rec.sellingPrice
            })
            if(this.productBundle.bType!='FIXED'){
                this.productBundle.swapOptions[0].swapOptions.forEach(rec=>{
                    rec.price=rec.sellingPrice
                })
            }
        },
    }
}
</script>
