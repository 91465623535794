<template>
<v-layout>
    <v-flex xs4>
        <v-col>
            <v-row class="align-card-image">
                <v-img v-if="product.images?.length>0&&product.images[0]" :src="imagePath(product.images[0].path)" height="64px" width="64px"></v-img>
            </v-row>
        </v-col>
    </v-flex>
    <v-flex xs6>
        <v-col>
            <v-row>
                <label wrap class="card-title-font" v-text="product.title"></label>
            </v-row>
            <v-row v-if="product.brand">
                <label class="card-subtitle-font" v-text="product.brand.displayName"></label>
            </v-row>
            <v-row>
                <label class="card-subtitle-font" v-text="product.code"></label>
            </v-row>
        </v-col>
    </v-flex>
    <v-flex text-right class="vertical-center" xs2>
        <label class="card-subtitle-font">Rs</label>
        <label class="card-title-font">{{product.price}}</label>
    </v-flex>
    <v-menu v-if="icons" left>
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
        </template>
        <v-list>
            <v-list-item v-for="(item, index) in icons" :key="index">
                <v-btn fab dark x-small class="ma-2" :style="theme">
                    <v-icon :show="isAllowed(item.permission)" @click="$emit('action',item,product)">{{ item.name }}</v-icon>
                </v-btn>
            </v-list-item>
        </v-list>
    </v-menu>
</v-layout>
</template>

<script>
export default {
    props: ["icons", "product"],
};
</script>